import axios from '@axios'

export default {
    namespaced: true,
    state: {},
    getters: {},
    mutations: {},
    actions: {
        fetchItems(ctx, queryParams) {
            return new Promise((resolve, reject) => {
                axios
                    .get('/customers', {
                        params: queryParams,
                    })
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        previewItem(ctx, {id}) {
            return new Promise((resolve, reject) => {
                axios
                    .get(`/customers/view/${id}`)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        fetchItem(ctx, {id}) {
            return new Promise((resolve, reject) => {
                axios
                    .get(`/customers/${id}`)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        addItem(ctx, dataInfo) {
            return new Promise((resolve, reject) => {
                axios
                    .post('/customers', dataInfo)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        editItem(ctx, dataInfo) {
            return new Promise((resolve, reject) => {
                axios
                    .put('/customers', dataInfo)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        removeItem(ctx, {id}) {
            return new Promise((resolve, reject) => {
                axios
                    .delete(`/customers/${id}`)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        demandItem(ctx, {id}) {
            return new Promise((resolve, reject) => {
                axios
                    .post(`/customers/demand-request/${id}`)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        fetchAddressList(ctx, {customerId, queryParams}) {
            return new Promise((resolve, reject) => {
                axios
                    .get(`/customeraddress/${customerId}`, {
                        params: queryParams,
                    })
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        previewAddress(ctx, {customerId, id}) {
            return new Promise((resolve, reject) => {
                axios
                    .get(`/customeraddress/${customerId}/view/${id}`)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        fetchAddress(ctx, {customerId, id}) {
            return new Promise((resolve, reject) => {
                axios
                    .get(`/customeraddress/${customerId}/${id}`)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        addAddress(ctx, {customerId, dataInfo}) {
            return new Promise((resolve, reject) => {
                axios
                    .post(`/customeraddress/${customerId}`, dataInfo)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        editAddress(ctx, {customerId, dataInfo}) {
            return new Promise((resolve, reject) => {
                axios
                    .put(`/customeraddress/${customerId}`, dataInfo)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        removeAddress(ctx, {customerId, id}) {
            return new Promise((resolve, reject) => {
                axios
                    .delete(`/customeraddress/${customerId}/${id}`)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        fetchContactList(ctx, {customerId, queryParams}) {
            return new Promise((resolve, reject) => {
                axios
                    .get(`/customercontacts/${customerId}`, {
                        params: queryParams,
                    })
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        previewContact(ctx, {customerId, id}) {
            return new Promise((resolve, reject) => {
                axios
                    .get(`/customercontacts/${customerId}/view/${id}`)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        fetchContact(ctx, {customerId, id}) {
            return new Promise((resolve, reject) => {
                axios
                    .get(`/customercontacts/${customerId}/${id}`)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        addContact(ctx, {customerId, dataInfo}) {
            return new Promise((resolve, reject) => {
                axios
                    .post(`/customercontacts/${customerId}`, dataInfo)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        editContact(ctx, {customerId, dataInfo}) {
            return new Promise((resolve, reject) => {
                axios
                    .put(`/customercontacts/${customerId}`, dataInfo)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        removeContact(ctx, {customerId, id}) {
            return new Promise((resolve, reject) => {
                axios
                    .delete(`/customercontacts/${customerId}/${id}`)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        fetchCapacityList(ctx, {customerId, queryParams}) {
            return new Promise((resolve, reject) => {
                axios
                    .get(`/customercapacities/${customerId}`, {
                        params: queryParams,
                    })
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        previewCapacity(ctx, {customerId, id}) {
            return new Promise((resolve, reject) => {
                axios
                    .get(`/customercapacities/${customerId}/view/${id}`)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        fetchCapacity(ctx, {customerId, id}) {
            return new Promise((resolve, reject) => {
                axios
                    .get(`/customercapacities/${customerId}/${id}`)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        addCapacity(ctx, {customerId, dataInfo}) {
            return new Promise((resolve, reject) => {
                axios
                    .post(`/customercapacities/${customerId}`, dataInfo)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        editCapacity(ctx, {customerId, dataInfo}) {
            return new Promise((resolve, reject) => {
                axios
                    .put(`/customercapacities/${customerId}`, dataInfo)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        removeCapacity(ctx, {customerId, id}) {
            return new Promise((resolve, reject) => {
                axios
                    .delete(`/customercapacities/${customerId}/${id}`)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
    },
}
