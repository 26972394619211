<template>
  <b-modal
      no-close-on-backdrop
      centered
      hide-header-close
      hide-footer
      ok-variant="dark"
      title="Yönetici Yorumları"
      modal-class="modal-dark"
      v-model="isActionCommentModal"
      size="lg">

    <div v-for="(data,index) in commentsData" :key="data.id" class="profile-twitter-feed" :class="index !== 0 ? 'mt-2' : 'mt-1'">
      <div class="d-flex justify-content-start align-items-center mb-1">
        <b-avatar
            class="mr-1"
            :src="getApiFile(data.userProfilePicture)"
            :text="avatarText(data.userName)"
            :variant="`light-primary`"
            size="40"/>
        <div class="profile-user-info">
          <h6 class="mb-0">
            {{ data.userName }}
          </h6>
          <small class="text-muted">{{ formatDateTime(data.date) }}</small>
        </div>
        <div class="profile-star ml-auto">
          <div class="item-rating">
            <ul class="unstyled-list list-inline">
              <li v-for="star in 5" :key="star" class="ratings-list-item" :class="{'ml-25': star-1}">
                <feather-icon icon="StarIcon" size="16" :class="[{'fill-current': star <= data.rating}, star <= data.rating ? 'text-warning' : 'text-muted']"/>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <b-card-text class="mb-50 pb-1">
        {{ data.description }}
      </b-card-text>
    </div>

    <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
      <b-form @submit.prevent="handleSubmit(onSubmit)" enctype="multipart/form-data">
        <b-row class="mt-1">
          <b-col cols="12">
            <div class="d-flex justify-content-end">
              <b-form-rating v-model="dataInfo.rating" inline no-border variant="warning" class="d-block pr-0"/>
            </div>
          </b-col>
          <b-col cols="12">
            <validation-provider #default="validationContext" name="Yorum" rules="required">
              <b-form-group>
                <b-form-textarea v-model="dataInfo.description" rows="3" placeholder="Yorumunuz..."/>
                <b-form-invalid-feedback :state="getValidationState(validationContext)">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>
        <b-button
            size="sm"
            variant="primary"
            class="mb-1 mb-sm-0 mr-0 mr-sm-1"
            :block="$store.getters['app/currentBreakPoint'] === 'xs'"
            type="submit">
          <feather-icon icon="SaveIcon" size="16"/>
          <span class="align-middle"> Kaydet</span>
        </b-button>
      </b-form>
    </validation-observer>

    <div class="border-bottom my-1"></div>

    <b-row class="d-flex justify-content-end align-items-center">
      <b-button
          class="mr-1"
          variant="outline-secondary"
          :block="$store.getters['app/currentBreakPoint'] === 'xs'"
          @click="$emit('update:is-action-comment-modal', false)">
        <feather-icon icon="XCircleIcon" size="16"/>
        <span class="align-middle" role="button"> Kapat</span>
      </b-button>
    </b-row>

    <Overlay :busy="busy"></Overlay>
  </b-modal>
</template>

<script>
import {ref, watch} from '@vue/composition-api'
import {BAvatar, BButton, BCard, BCardText, BCol, BForm, BFormGroup, BFormInvalidFeedback, BFormRating, BFormTextarea, BLink, BModal, BRow} from "bootstrap-vue"
import {useToast} from "vue-toastification/composition"
import Overlay from "@/components/Overlay.vue";
import store from "@/store";
import {toastMessage} from "@core/utils/utils"
import {avatarText, getApiFile, formatDateTime} from "@core/utils/filter"
import {ValidationProvider, ValidationObserver} from 'vee-validate'
import formValidation from '@core/comp-functions/forms/form-validation'
import {required} from '@validations'

export default {
  components: {
    BFormInvalidFeedback,
    BCol,
    BForm,
    BFormRating,
    BFormTextarea,
    BFormGroup,
    BCard,
    BCardText,
    BLink,
    BAvatar,
    BButton,
    BRow,
    BModal,

    Overlay,

    ValidationProvider,
    ValidationObserver,
  },
  model: [
    {
      prop: 'isActionCommentModal',
      event: 'update:is-action-comment-modal',
    },
    {
      prop: 'dataRecord',
      event: 'update:data-record',
    }
  ],
  props: {
    isActionCommentModal: {
      type: Boolean,
      required: true,
    },
    dataRecord: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      required,
    }
  },
  setup(props, {emit}) {
    var activityActionId = 0

    const toast = useToast()
    const busy = ref(false)
    const commentsData = ref([])
    const dataInfo = ref({
      id: 0,
      description: "",
      rating: 0
    })

    const getCommentList = () => {
      busy.value = true
      store.dispatch('store/fetchActionCommentList', {activityActionId: activityActionId}).then(response => {
        commentsData.value = response.data.data
      }).catch(error => {
        toastMessage(toast, 'danger', error.response.data.message)
        if (error.response.status === 403) {
          emit('update:is-action-comment-modal', false)
          emit('update:data-record', {})
        }
      }).finally(message => {
        busy.value = false
      })
    }

    const onSubmit = () => {
      busy.value = true
      store.dispatch('store/addActionComment', {activityActionId: activityActionId, dataInfo: dataInfo.value}).then(response => {
        toastMessage(toast, 'success', response.data.message)
        getCommentList()
        resetForm()
      }).catch(error => {
        toastMessage(toast, 'danger', error.response.data.message)
      }).finally(message => {
        busy.value = false
      })
    }

    const resetRecordData = () => {
      dataInfo.value.id = 0
      dataInfo.value.rating = 0
      dataInfo.value.description = ''
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetRecordData)

    watch(() => props.dataRecord, (dataValue) => {
      if (dataValue.id !== undefined && props.isActionCommentModal) {
        activityActionId = dataValue.id
        getCommentList()
      } else {
        activityActionId = 0
      }
    })

    return {
      refFormObserver,
      busy,
      commentsData,
      dataInfo,

      onSubmit,
      getValidationState,
      resetForm,
      avatarText,
      getApiFile,
      formatDateTime
    }
  },
}
</script>
