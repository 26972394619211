<template>
    <b-tab v-if="$router.currentRoute.params.id > 0 && isVisible">
        <template #title>
            <feather-icon icon="CodesandboxIcon"/>
            <span>Döküman Bilgileri</span>
        </template>
        <b-card no-body class="border mt-1 mb-0">
            <div class="m-1">
                <b-row>
                    <b-col cols="12" md="6" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
                        <v-select v-model="perPage" class="per-page-selector d-inline-block mx-50" :options="perPageOptions" :clearable="false"/>
                    </b-col>
                    <b-col cols="12" md="6">
                        <div class="d-flex align-items-center justify-content-end">
                            <b-form-input v-model="searchQuery" class="d-inline-block mr-1" placeholder="Arama..."/>
                            <DocumentDetail :is-document-new-sidebar-active.sync="isDocumentNewSidebarActive" :record-data-id="0"></DocumentDetail>
                            <b-button-toolbar justify>
                                <b-button-group>
                                    <b-button @click="reFetchData" variant="outline-primary" size="md">
                                        <feather-icon icon="RefreshCcwIcon" size="18"/>
                                        <span class="align-middle"></span>
                                    </b-button>
                                    <b-button @click="isDocumentNewSidebarActive = true;" variant="primary" size="md">
                                        <feather-icon icon="PlusCircleIcon" size="18"/>
                                        <span class="align-middle"></span>
                                    </b-button>
                                </b-button-group>
                            </b-button-toolbar>

                        </div>
                    </b-col>
                </b-row>
            </div>

            <b-table
                    ref="refListTable"
                    primary-key="id"
                    empty-text="Kayıt bulunamadı"
                    :class="`position-relative ${totalCount > 0 && totalCount < 3 ? 'dropdown-h150': ''}`"
                    :items="fetchList"
                    :fields="tableColumns"
                    :sort-by.sync="sortBy"
                    :sort-desc.sync="isSortDirDesc"
                    responsive striped bordered hover show-empty>

                <template #cell(id)="data">
                    <b-link class="font-weight-bold">#{{ data.value }}</b-link>
                </template>

                <template #cell(createdOn)="data">
                    <date-time-column-table :data="data.value"></date-time-column-table>
                </template>

                <template #cell(isCancelled)="data">
                    <boolean-column-table :data="data.value"></boolean-column-table>
                </template>

                <template #cell(actions)="data">
                    <div class="text-nowrap">
                        <b-dropdown text="Dropdown" variant="link" no-caret toggle-class="p-0">
                            <template #button-content>
                                <feather-icon icon="MoreVerticalIcon" size="16" class="align-middle text-body"/>
                            </template>
                            <b-dropdown-item @click="cancelDocument(data.item)">
                                <feather-icon icon="XIcon"/>
                                <span class="align-middle ml-50">İptal</span>
                            </b-dropdown-item>
                            <b-dropdown-item @click="removeDocument(data.item)">
                                <feather-icon icon="TrashIcon"/>
                                <span class="align-middle ml-50">Çıkar</span>
                            </b-dropdown-item>
                        </b-dropdown>
                        <b-link class="font-weight-bold" :href="getApiFile(data.item.filePath)" target="_blank">
                            <feather-icon :id="`row-${data.item.id}-prev`" icon="EyeIcon" size="16" class="mx-1"/>
                        </b-link>
                        <b-tooltip title="Görüntüle" class="cursor-pointer" :target="`row-${data.item.id}-prev`"/>
                    </div>
                </template>

            </b-table>

            <div class="mx-2 mb-2">
                <b-row>
                    <footer-table :data-meta="dataMeta"></footer-table>
                    <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">
                        <b-pagination class="mb-0 mt-1 mt-sm-0" prev-class="prev-item" next-class="next-item" v-model="currentPage" :total-rows="totalCount" :per-page="perPage" first-number last-number>

                            <template #prev-text>
                                <feather-icon icon="ChevronLeftIcon" size="18"/>
                            </template>

                            <template #next-text>
                                <feather-icon icon="ChevronRightIcon" size="18"/>
                            </template>

                        </b-pagination>
                    </b-col>
                </b-row>
            </div>

            <Overlay :busy="busy"></Overlay>
        </b-card>
    </b-tab>
</template>

<script>
import {BCard, BRow, BCol, BFormInput, BTable, BPagination, BTooltip, BTab, BButtonToolbar, BButtonGroup, BButton, BLink, BBadge, BDropdown, BDropdownItem} from 'bootstrap-vue'
import {FooterTable, DateTimeColumnTable} from '@/components/Table';
import vSelect from 'vue-select'
import Overlay from "@/components/Overlay.vue";
import {ref, watch} from '@vue/composition-api'
import useList from './useList'
import DocumentDetail from "@/views/activity/document/Detail.vue";
import Vue from "vue";
import store from "@/store";
import router from "@/router";
import BooleanColumnTable from "@/components/Table/BooleanColumnTable.vue";
import {getApiFile} from "@core/utils/filter";

export default {
    name: "DocumentList",
    components: {
        BDropdownItem,
        BDropdown,
        BBadge,
        BLink,
        BButton,
        BButtonGroup,
        BButtonToolbar,
        BCard,
        BRow,
        BCol,
        BFormInput,
        BTable,
        BPagination,
        BTooltip,
        BTab,

        BooleanColumnTable,
        DocumentDetail,
        Overlay,
        FooterTable,
        DateTimeColumnTable,
        vSelect,
    },
    setup() {

        const {
            tableColumns,
            perPage,
            currentPage,
            totalCount,
            dataMeta,
            perPageOptions,
            searchQuery,
            sortBy,
            isSortDirDesc,
            refListTable,
            busy,
            isVisible,

            fetchList,
            reFetchData,
        } = useList()

        const isDocumentNewSidebarActive = ref(false)

        const removeDocument = (dataItem) => {
            Vue.swal({
                title: 'Onaylıyor musunuz?',
                text: 'Kayıt çıkarılacaktır!',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Evet, Çıkar',
                cancelButtonText: 'Vazgeç',
                customClass: {
                    confirmButton: 'btn btn-primary',
                    cancelButton: 'btn btn-outline-danger ml-1',
                },
                buttonsStyling: false,
            }).then(result => {
                if (result.value) {
                    store.dispatch('store/removeDocument', {activityId: router.currentRoute.params.id, id: dataItem.id}).then(response => {
                        if (response.data.success) {
                            reFetchData()
                            Vue.swal({
                                icon: 'success',
                                title: 'Başarılı!',
                                text: 'Kayıt çıkarıldı.',
                                confirmButtonText: 'Tamam',
                                customClass: {
                                    confirmButton: 'btn btn-primary',
                                },
                            })
                        }
                    }).catch(error => {
                        Vue.swal({
                            icon: 'error',
                            title: 'Hata!',
                            text: error.response.data.message,
                            confirmButtonText: 'Tamam',
                            customClass: {
                                confirmButton: 'btn btn-primary',
                            },
                        })
                    })
                }
            })
        }

        const cancelDocument = (dataItem) => {
            Vue.swal({
                title: 'Onaylıyor musunuz?',
                text: 'Kayıt iptal edilecektir!',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Evet, İptal',
                cancelButtonText: 'Vazgeç',
                customClass: {
                    confirmButton: 'btn btn-primary',
                    cancelButton: 'btn btn-outline-danger ml-1',
                },
                buttonsStyling: false,
            }).then(result => {
                if (result.value) {
                    store.dispatch('store/cancelDocument', {activityId: router.currentRoute.params.id, id: dataItem.id}).then(response => {
                        if (response.data.success) {
                            reFetchData()
                            Vue.swal({
                                icon: 'success',
                                title: 'Başarılı!',
                                text: 'Kayıt iptal edildi.',
                                confirmButtonText: 'Tamam',
                                customClass: {
                                    confirmButton: 'btn btn-primary',
                                },
                            })
                        }
                    }).catch(error => {
                        Vue.swal({
                            icon: 'error',
                            title: 'Hata!',
                            text: error.response.data.message,
                            confirmButtonText: 'Tamam',
                            customClass: {
                                confirmButton: 'btn btn-primary',
                            },
                        })
                    })
                }
            })
        }

        watch(isDocumentNewSidebarActive, (dataValue) => {
            if (dataValue === false) {
                reFetchData()
            }
        })

        return {
            busy,
            tableColumns,
            perPage,
            currentPage,
            totalCount,
            dataMeta,
            perPageOptions,
            searchQuery,
            sortBy,
            isSortDirDesc,
            refListTable,
            isVisible,
            isDocumentNewSidebarActive,

            fetchList,
            reFetchData,

            removeDocument,
            cancelDocument,

            getApiFile,
        }
    }
}
</script>