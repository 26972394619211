import {ref, watch, computed} from '@vue/composition-api'
import store from '@/store'
import {useToast} from 'vue-toastification/composition'
import {toastMessage} from "@core/utils/utils";
import router from "@/router";
import {perPageOptions} from "@core/utils/filter";

export default function useList() {
    const toast = useToast()
    const refListTable = ref(null)

    const tableColumns = [
        {key: 'id', label: '#', sortable: true, thStyle: {width: "1%"}},
        {key: 'sortNumber', label: 'Sıra', sortable: true},
        {key: 'startDate', label: 'Başlama Zamanı', sortable: true},
        {key: 'endDate', label: 'Bitiş Zamanı', sortable: true},
        {key: 'title', label: 'Başlık', sortable: true},
        {key: 'dueDate', label: 'Termin Tarihi', sortable: true},
        {key: 'activityActionStatus', label: 'Durum', sortable: true},
        {key: 'isCancelled', label: 'İptal', sortable: true},
        {key: 'actions', label: 'İşlemler', thStyle: {width: "2%"}},
    ]

    const busy = ref(false)
    const perPage = ref(10)
    const totalCount = ref(0)
    const currentPage = ref(1)
    const searchQuery = ref('')
    const sortBy = ref('id')
    const isSortDirDesc = ref(true)
    const isVisible = ref(true)

    const dataMeta = computed(() => {
        const localItemsCount = refListTable.value ? refListTable.value.localItems.length : 0
        return {
            from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
            to: perPage.value * (currentPage.value - 1) + localItemsCount,
            of: totalCount.value,
        }
    })

    const reFetchData = () => {
        refListTable.value.refresh()
    }

    watch([currentPage, perPage, searchQuery], () => {
        reFetchData()
    })

    const fetchList = (ctx, callback) => {
        busy.value = true
        store.dispatch('store/fetchActionList', {
            activityId: router.currentRoute.params.id,
            queryParams: {
                search: searchQuery.value,
                start: perPage.value * (currentPage.value - 1),
                length: perPage.value,
                sortColumn: sortBy.value,
                sortDesc: isSortDirDesc.value,
            },
        }).then(response => {
            callback(response.data.data)
            totalCount.value = response.data.count
        }).catch(error => {
            if (error.response.status === 403) {
                isVisible.value = false
            } else {
                toastMessage(toast, 'danger', error.response.data.message)
            }
        }).finally(() => {
            busy.value = false
        })
    }

    return {
        tableColumns,
        perPage,
        currentPage,
        totalCount,
        dataMeta,
        searchQuery,
        sortBy,
        isSortDirDesc,
        refListTable,
        busy,
        isVisible,

        perPageOptions,

        fetchList,
        reFetchData,
    }
}