import axios from '@axios'
import qs from "qs";

export default {
    namespaced: true,
    state: {},
    getters: {},
    mutations: {},
    actions: {
        fetchItems(ctx, queryParams) {
            return new Promise((resolve, reject) => {
                axios
                    .get('/activities', {
                        params: queryParams,
                    })
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        previewItem(ctx, {id}) {
            return new Promise((resolve, reject) => {
                axios
                    .get(`/activities/view/${id}`)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        fetchItem(ctx, {id}) {
            return new Promise((resolve, reject) => {
                axios
                    .get(`/activities/${id}`)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        addItem(ctx, dataInfo) {
            return new Promise((resolve, reject) => {
                axios
                    .post('/activities', dataInfo)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        editItem(ctx, dataInfo) {
            return new Promise((resolve, reject) => {
                axios
                    .put('/activities', dataInfo)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        removeItem(ctx, {id}) {
            return new Promise((resolve, reject) => {
                axios
                    .delete(`/activities/${id}`)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        cancelItem(ctx, {id}) {
            return new Promise((resolve, reject) => {
                axios
                    .post(`/activities/cancel/${id}`)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        fetchActionList(ctx, {activityId, queryParams}) {
            return new Promise((resolve, reject) => {
                axios
                    .get(`/activityactions/${activityId}`, {
                        params: queryParams,
                    })
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        previewAction(ctx, {activityId, id}) {
            return new Promise((resolve, reject) => {
                axios
                    .get(`/activityactions/${activityId}/view/${id}`)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        fetchAction(ctx, {activityId, id}) {
            return new Promise((resolve, reject) => {
                axios
                    .get(`/activityactions/${activityId}/${id}`)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        addAction(ctx, {activityId, dataInfo}) {
            return new Promise((resolve, reject) => {
                axios
                    .post(`/activityactions/${activityId}`, dataInfo)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        editAction(ctx, {activityId, dataInfo}) {
            return new Promise((resolve, reject) => {
                axios
                    .put(`/activityactions/${activityId}`, dataInfo)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        removeAction(ctx, {activityId, id}) {
            return new Promise((resolve, reject) => {
                axios
                    .delete(`/activityactions/${activityId}/${id}`)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        cancelAction(ctx, {activityId, id}) {
            return new Promise((resolve, reject) => {
                axios
                    .post(`/activityactions/cancel/${activityId}/${id}`)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        fetchCommentList(ctx, {activityId, queryParams}) {
            return new Promise((resolve, reject) => {
                axios
                    .get(`/activitycomments/${activityId}`, {
                        params: queryParams,
                    })
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        addComment(ctx, {activityId, dataInfo}) {
            return new Promise((resolve, reject) => {
                axios
                    .post(`/activitycomments/${activityId}`, dataInfo)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        fetchActionCommentList(ctx, {activityActionId , queryParams}) {
            return new Promise((resolve, reject) => {
                axios
                    .get(`/activityactioncomments/${activityActionId }`, {
                        params: queryParams,
                    })
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        addActionComment(ctx, {activityActionId , dataInfo}) {
            return new Promise((resolve, reject) => {
                axios
                    .post(`/activityactioncomments/${activityActionId }`, dataInfo)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        fetchDocumentList(ctx, {activityId, queryParams}) {
            return new Promise((resolve, reject) => {
                axios
                    .get(`/activitydocuments/${activityId}`, {
                        params: queryParams,
                    })
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        previewDocument(ctx, {activityId, id}) {
            return new Promise((resolve, reject) => {
                axios
                    .get(`/activitydocuments/${activityId}/view/${id}`)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        addDocument(ctx, {activityId, dataInfo}) {
            return new Promise((resolve, reject) => {
                axios
                    .post(`/activitydocuments/${activityId}`, dataInfo)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        removeDocument(ctx, {activityId, id}) {
            return new Promise((resolve, reject) => {
                axios
                    .delete(`/activitydocuments/${activityId}/${id}`)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        cancelDocument(ctx, {activityId, id}) {
            return new Promise((resolve, reject) => {
                axios
                    .post(`/activitydocuments/cancel/${activityId}/${id}`)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        export(ctx, queryParams) {
            return new Promise((resolve, reject) => {
                axios
                    .get('/activities/export', {
                        params: queryParams,
                    })
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
    },
}
