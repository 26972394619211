<template>
  <b-card>
    <b-tabs pills>
      <b-tab active>
        <template #title>
          <feather-icon icon="CodesandboxIcon"/>
          <span>Aktivite Bilgileri</span>
        </template>
        <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
          <b-form @submit.prevent="handleSubmit(onSubmit)">
            <b-row>
              <b-col cols="12" md="4" lg="4">
                <b-row>
                  <b-col cols="12" md="12" lg="12">
                    <validation-provider #default="validationContext" name="Başlama Zamanı" rules="required">
                      <b-form-group label="Başlama Zamanı" :state="getValidationState(validationContext)">
                        <flat-pickr v-model="dataInfo.startDate" class="form-control" :config="{enableTime: true, altInput: true, altFormat: 'd.m.Y H:i', dateFormat: 'Y-m-d H:i', time_24hr: true, wrap: true, locale: { firstDayOfWeek: 1}, weekStart: 1}"/>
                        <b-form-invalid-feedback :state="getValidationState(validationContext)">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col cols="12" md="12" lg="12">
                    <validation-provider #default="validationContext" name="Bitiş Zamanı" rules="required">
                      <b-form-group label="Bitiş Zamanı" :state="getValidationState(validationContext)">
                        <flat-pickr v-model="dataInfo.endDate" class="form-control" :config="{enableTime: true, altInput: true, altFormat: 'd.m.Y H:i', dateFormat: 'Y-m-d H:i', time_24hr: true, wrap: true, locale: { firstDayOfWeek: 1}, weekStart: 1}"/>
                        <b-form-invalid-feedback :state="getValidationState(validationContext)">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col cols="12" md="12" lg="12">
                    <validation-provider #default="validationContext" name="Aktivite Tipi" rules="required">
                      <b-form-group label="Aktivite Tipi" :state="getValidationState(validationContext)">
                        <v-select v-model="dataInfo.activityTypeId" :options="activityTypeOptions" :reduce="val => val.value" :clearable="true"/>
                        <b-form-invalid-feedback :state="getValidationState(validationContext)">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col cols="12" md="12" lg="12">
                    <validation-provider #default="validationContext" name="Aktivite Konusu" rules="required">
                      <b-form-group label="Aktivite Konusu" :state="getValidationState(validationContext)">
                        <v-select v-model="dataInfo.activitySubjectId" :options="activitySubjectOptions" :reduce="val => val.value" :clearable="true"/>
                        <b-form-invalid-feedback :state="getValidationState(validationContext)">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col cols="12" md="12" lg="12">
                    <validation-provider #default="validationContext" name="Aktivite Lokasyonu" rules="required">
                      <b-form-group label="Aktivite Lokasyonu" :state="getValidationState(validationContext)">
                        <v-select v-model="dataInfo.activityLocationId" :options="activityLocationOptions" :reduce="val => val.value" :clearable="true"/>
                        <b-form-invalid-feedback :state="getValidationState(validationContext)">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col cols="12" md="12" lg="12">
                    <validation-provider #default="validationContext" name="Aktivite Sonucu" rules="required">
                      <b-form-group label="Aktivite Sonucu" :state="getValidationState(validationContext)">
                        <v-select v-model="dataInfo.activityConclusionId" :options="activityConclusionOptions" :reduce="val => val.value" :clearable="true"/>
                        <b-form-invalid-feedback :state="getValidationState(validationContext)">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col cols="12" md="12" lg="12">
                    <validation-provider #default="validationContext" name="Planlı" rules="required">
                      <b-form-group label="Planlı" :state="getValidationState(validationContext)">
                        <v-select v-model="dataInfo.isPlanned" :options="yesNoOptions" :reduce="val => val.value" :clearable="false"/>
                        <b-form-invalid-feedback :state="getValidationState(validationContext)">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col cols="12" md="12" lg="12">
                    <validation-provider #default="validationContext" name="Müşteri" rules="required">
                      <b-form-group label="Müşteri" :state="getValidationState(validationContext)">
                        <v-select v-model="dataInfo.customerId" :options="customerOptions" :reduce="val => val.value" :clearable="true" v-on:input="getCustomerContacts"/>
                        <b-form-invalid-feedback :state="getValidationState(validationContext)">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col cols="12" md="12" lg="12">
                    <validation-provider #default="validationContext" name="İlgili Kişi" rules="required">
                      <b-form-group label="İlgili Kişi" :state="getValidationState(validationContext)">
                        <v-select v-model="dataInfo.customerContactId" :options="customerContactOptions" :reduce="val => val.value" :clearable="true"/>
                        <b-form-invalid-feedback :state="getValidationState(validationContext)">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col cols="12" md="12" lg="12">
                    <validation-provider #default="validationContext" name="Proje">
                      <b-form-group label="Proje" :state="getValidationState(validationContext)">
                        <v-select v-model="dataInfo.projectId" :options="projectOptions" :reduce="val => val.value" :clearable="true"/>
                        <b-form-invalid-feedback :state="getValidationState(validationContext)">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col cols="12" md="12" lg="12">
                    <validation-provider #default="validationContext" name="Tekrar Görüşülecek" rules="required">
                      <b-form-group label="Tekrar Görüşülecek" :state="getValidationState(validationContext)">
                        <v-select v-model="dataInfo.againMeet" :options="yesNoOptions" :reduce="val => val.value" :clearable="false"/>
                        <b-form-invalid-feedback :state="getValidationState(validationContext)">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                </b-row>
              </b-col>
              <b-col cols="12" md="8" lg="8">
                <b-col cols="12" md="12" lg="12">
                  <validation-provider #default="validationContext" name="Açıklama" rules="required">
                    <b-form-group label="Açıklama">
                      <b-form-textarea trim placeholder="Açıklama" v-model="dataInfo.description" :state="getValidationState(validationContext)" rows="30"/>
                      <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
              </b-col>
            </b-row>
            <action-buttons :back-route="'activity-list'"/>
          </b-form>
        </validation-observer>
      </b-tab>
      <ActionList></ActionList>
      <DocumentList></DocumentList>
    </b-tabs>
    <Overlay :busy="busy"></Overlay>
  </b-card>
</template>

<script>
import {
  BCard, BRow, BCol, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BTabs, BTab, BTable, BFormTextarea, BButton, BTooltip
} from 'bootstrap-vue'
import formValidation from '@core/comp-functions/forms/form-validation'
import {onUnmounted, ref} from '@vue/composition-api'
import {useToast} from 'vue-toastification/composition'
import {toastMessage} from "@core/utils/utils"
import router from '@/router'
import {ValidationProvider, ValidationObserver} from 'vee-validate'
import {required} from '@validations'
import Overlay from "@/components/Overlay.vue"
import ActionButtons from "@/components/Form/ActionButtons.vue"
import vSelect from 'vue-select'
import store from "@/store"
import storeModule from "@/views/activity/store"
import definitionModule from "@/views/system/definition/store"
import customerModule from "@/views/definition/customer/store"
import projectModule from "@/views/definition/project/store"
import flatPickr from 'vue-flatpickr-component'
import {yesNoOptions} from "@core/utils/filter"
import ActionList from "@/views/activity/action/List.vue"
import DocumentList from "@/views/activity/document/List.vue"

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BTabs,
    BTab,
    BTable,
    BFormTextarea,
    BButton,
    BTooltip,
    flatPickr,

    ActionButtons,
    ActionList,
    DocumentList,
    Overlay,
    vSelect,

    ValidationProvider,
    ValidationObserver,
  },
  props: {},
  data() {
    return {
      required,
    }
  },
  setup() {
    const STORE_MODULE_NAME = 'store'
    const STORE_DEFINITION_MODULE_NAME = 'store-definition'
    const STORE_CUSTOMER_MODULE_NAME = 'store-customer'
    const STORE_PROJECT_MODULE_NAME = 'store-project'
    if (!store.hasModule(STORE_MODULE_NAME)) {
      store.registerModule(STORE_MODULE_NAME, storeModule)
      store.registerModule(STORE_DEFINITION_MODULE_NAME, definitionModule)
      store.registerModule(STORE_CUSTOMER_MODULE_NAME, customerModule)
      store.registerModule(STORE_PROJECT_MODULE_NAME, projectModule)
    }
    onUnmounted(() => {
      if (store.hasModule(STORE_MODULE_NAME)) {
        store.unregisterModule(STORE_MODULE_NAME)
        store.unregisterModule(STORE_DEFINITION_MODULE_NAME)
        store.unregisterModule(STORE_CUSTOMER_MODULE_NAME)
        store.unregisterModule(STORE_PROJECT_MODULE_NAME)
      }
    })

    const toast = useToast()
    const busy = ref(false)
    const dataInfo = ref({
      id: 0,
      startDate: null,
      endDate: null,
      activityTypeId: '',
      activitySubjectId: '',
      activityLocationId: '',
      activityConclusionId: '',
      customerId: null,
      customerContactId: null,
      description: '',
      againMeet: false,
      isPlanned: false,
      projectId: null,
    })

    const activityTypeOptions = ref([])
    const activitySubjectOptions = ref([])
    const activityLocationOptions = ref([])
    const customerOptions = ref([])
    const customerContactOptions = ref([])
    const projectOptions = ref([])
    const activityConclusionOptions = ref([])

    const onSubmit = () => {
      busy.value = true
      store.dispatch('store/' + (router.currentRoute.params.id > 0 ? 'editItem' : 'addItem'), dataInfo.value).then(response => {
        toastMessage(toast, 'success', response.data.message)
        router.push({name: 'activity-list'})
      }).catch(error => {
        toastMessage(toast, 'danger', error.response.data.message)
      }).finally(message => {
        busy.value = false
      })
    }

    const getCustomerContacts = (val) => {
      customerContactOptions.value = []

      if (val != null) {
        busy.value = true
        store.dispatch('store-customer/fetchContactList', {customerId: val}).then(response => {
          response.data.data.forEach((value, index) => {
            customerContactOptions.value.push({label: value.name + ' ' + value.lastName, value: value.id})
          });
        }).catch(error => {
          toastMessage(toast, 'danger', error.response.data.message)
        }).finally(message => {
          busy.value = false
        })
      } else {
        dataInfo.value.customerContactId = null
      }
    }

    busy.value = true
    store.dispatch('store-definition/definitionList', {groupKey: 'ACTIVITY_TYPE'}).then(response => {
      response.data.data.forEach((value, index) => {
        activityTypeOptions.value.push({label: value.name, value: value.key})
        if (value.isDefault && router.currentRoute.params.id === undefined) {
          dataInfo.value.activityTypeId = value.key
        }
      });
    }).catch(error => {
      toastMessage(toast, 'danger', error.response.data.message)
    }).finally(message => {
      busy.value = false
    })

    busy.value = true
    store.dispatch('store-definition/definitionList', {groupKey: 'ACTIVITY_SUBJECT'}).then(response => {
      response.data.data.forEach((value, index) => {
        activitySubjectOptions.value.push({label: value.name, value: value.key})
        if (value.isDefault && router.currentRoute.params.id === undefined) {
          dataInfo.value.activitySubjectId = value.key
        }
      });
    }).catch(error => {
      toastMessage(toast, 'danger', error.response.data.message)
    }).finally(message => {
      busy.value = false
    })

    busy.value = true
    store.dispatch('store-definition/definitionList', {groupKey: 'ACTIVITY_LOCATION'}).then(response => {
      response.data.data.forEach((value, index) => {
        activityLocationOptions.value.push({label: value.name, value: value.key})
        if (value.isDefault && router.currentRoute.params.id === undefined) {
          dataInfo.value.activityLocationId = value.key
        }
      });
    }).catch(error => {
      toastMessage(toast, 'danger', error.response.data.message)
    }).finally(message => {
      busy.value = false
    })

    busy.value = true
    store.dispatch('store-definition/definitionList', {groupKey: 'ACTIVITY_CONCLUSION'}).then(response => {
      response.data.data.forEach((value, index) => {
        activityConclusionOptions.value.push({label: value.name, value: value.key})
        if (value.isDefault && router.currentRoute.params.id === undefined) {
          dataInfo.value.activityConclusionId = value.key
        }
      });
    }).catch(error => {
      toastMessage(toast, 'danger', error.response.data.message)
    }).finally(message => {
      busy.value = false
    })

    busy.value = true
    store.dispatch('store-project/fetchItems').then(response => {
      response.data.data.forEach((value, index) => {
        if (value.isActive) {
          projectOptions.value.push({label: value.name, value: value.id})
        }
      });
    }).catch(error => {
      toastMessage(toast, 'danger', error.response.data.message)
    }).finally(message => {
      busy.value = false
    })

    busy.value = true
    store.dispatch('store-customer/fetchItems', {'status': true, 'all': true}).then(response => {
      response.data.data.forEach((value, index) => {
        if (value.isActive) {
          customerOptions.value.push({label: value.name, value: value.id})
        }
      });
    }).catch(error => {
      toastMessage(toast, 'danger', error.response.data.message)
    }).finally(message => {
      busy.value = false
    })

    if (router.currentRoute.params.id > 0) {
      busy.value = true
      store.dispatch('store/fetchItem', {id: router.currentRoute.params.id}).then(response => {
        dataInfo.value = response.data.data
        if (dataInfo.value.customerId > 0) {
          getCustomerContacts(dataInfo.value.customerId)
        }
      }).catch(error => {
        toastMessage(toast, 'danger', error.response.data.message)
        if (error.response.status === 403) {
          router.push({name: 'activity-list'})
        }
      }).finally(message => {
        busy.value = false
      })
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation()

    return {
      busy,
      dataInfo,
      refFormObserver,

      activityTypeOptions,
      activitySubjectOptions,
      activityLocationOptions,
      customerOptions,
      customerContactOptions,
      projectOptions,
      activityConclusionOptions,

      yesNoOptions,

      onSubmit,
      getValidationState,
      resetForm,

      getCustomerContacts,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';

</style>